@layer base {
    @font-face {
        font-family: Roobert;
        font-weight: 400;
        src: url('./fonts/Roobert/RoobertRegular.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 500;
        src: url('./fonts/Roobert/RoobertMedium.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 600;
        src: url('./fonts/Roobert/RoobertSemiBold.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 700;
        src: url('./fonts/Roobert/RoobertBold.ttf') format('truetype');
    }

    @font-face {
        font-family: Roobert;
        font-weight: 800;
        src: url('./fonts/Roobert/RoobertHeavy.ttf') format('truetype');
    }
}



@tailwind base;
@tailwind components;
@tailwind utilities;